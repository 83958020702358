import React from 'react';
import Button from  '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import Login from './login';
import { IoMdLogIn } from 'react-icons/io';
import IconButton from '@material-ui/core/IconButton';
import { Link } from "react-router-dom";
import './login.dialog.css';
import { withStyles } from '@material-ui/core/styles';


const styles = {
  
  center:{
      textAlign:'center',
      margin:'auto'
      
  }
};

class LoginDialog extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            isActive: this.props.isActive,
            postId: this.props.postId,
            token:global.token,
            userId: this.props.userId,
            open:this.props.isOpen
        };
        
   
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);

        
    }

    

    Transition(props) {
      return <Slide direction="up" {...props} />;
    }

     handleClickOpen() {
      this.setState({
        open: true
      });
    }
  
     handleClose() {
      this.setState({
        open: false
      });
     
    }

  
    render() {
      const { classes } = this.props;
      return (

        <div>
          <IconButton onClick={this.handleClickOpen} color="inherit">
              <IoMdLogIn  />
            </IconButton>
         
          <Dialog className="dialog"
            open={this.state.open}
            TransitionComponent={this.Transition}
            keepMounted
            onClose={this.handleClose}
            aria-labelledby="alert-dialog-slide-title"
            aria-describedby="alert-dialog-slide-description"
          >
            <DialogTitle >{"Please Login : "}</DialogTitle>
            <DialogContent>
              
                
                <div className={classes.center}>
                  <Login redirectHome={true}></Login>
                  <Link onClick={this.handleClose} to="/register">Create Account</Link><br></br>  
                  </div>
            </DialogContent>
            <DialogActions>
           
              <Button onClick={this.handleClose} color="primary">
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      
      );
    }
  }

  
  export default withStyles(styles)(LoginDialog);
  
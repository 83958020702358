import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import './upload.css';

class Agreement extends React.Component {

    constructor(props) {
        super(props);
        this.pond = React.createRef();
       
        this.state = { 
        
            open: this.props.open,
            agree:this.props.checked
        };

    }

    
    render() {

        return (
            <Dialog
                open={this.props.open}
                onClose={this.props.onClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                >
                <DialogTitle  id="alert-dialog-title"> <span className="dialogTitle">{"Dengan mengunggah konten di situs ketiwi.com maka Saya sebagai Pengguna menyatakan bahwa Saya setuju terhadap ketentuan berikut: "}</span></DialogTitle>
                <DialogContent>
                    
                    <ol>
                        <li>
                            Konten yang Saya unggah tidak termasuk dalam jenis <b>"Konten Yang Dilarang"</b> dalam situs ketiwi.com
                        </li>
                        <li>
                            Segala resiko/akibat yang timbul dari publikasi konten (Post, Like, Comment, dll) yang Saya unggah/buat pada situs ketiwi.com sepenuhnya menjadi tanggung jawab Saya pribadi sebagai pengunggah konten dan <b>Bukan</b> merupakan tangggung jawab ketiwi.com, admin ketiwi.com, maupun penyedia layanan webhost dari situs ketiwi.com
                        </li>
                        <li>
                            Saya sepenuhnya setuju bahwa ketiwi.com berhak untuk menyebarluaskan/ mempublikasikan konten yang Saya unggah/buat di ketiwi.com melalui sosial media, situs online, aplikasi, maupun media penyebaran informasi lainnya.
                        </li>
                        <li>
                            Saya setuju bahwa ketiwi.com berhak untuk menghapus konten (posting, komentar, suka, dll ) yang Saya unggah/buat maupun menonaktifkan akun Saya apabila saya tidak mematuhi sebagian maupun seluruh ketentuan pada situs ketiwi.com   
                        </li>
                        <li>
                            Demikian pernyataan Saya tanpa ada paksaan dari pihak manapun.  
                        </li>
                    </ol>

                </DialogContent>
                <DialogActions>
                    <Button onClick={this.props.notAgree} color="primary">
                    Tidak Setuju
                    </Button>
                    <Button onClick={this.props.agree} color="primary" autoFocus>
                    Setuju
                    </Button>
                </DialogActions>
            </Dialog>
                            
            );
    }
}

export default (Agreement);

//global.apiUrl="http://localhost/ketiwi.com/api/api/";
//global.baseUrl="http://localhost:3000";
//global.videoUrl="http://localhost/ketiwi.com/api/video/";
//global.memeUrl="http://localhost:3000/";
//global.imageUrl="http://localhost/ketiwi.com/api/images/";

//global.apiUrl="http://192.168.100.10/ketiwi.com/api/api/";
//global.baseUrl="http://192.168.100.10:3000";
//global.videoUrl="http://localhost/ketiwi.com/api/video/";
//global.memeUrl="http://192.168.100.10:3000/";
//global.imageUrl="http://192.168.100.10/ketiwi.com/api/images/";

global.apiUrl="https://ketiwi.com/api/api/";
global.baseUrl="https://ketiwi.com";
global.videoUrl="https://ketiwi.com/api/video/";
global.memeUrl="https://ketiwi.com/api/video/";
global.imageUrl="https://ketiwi.com/api/images/";

global.upload=global.apiUrl+"upload/";
global.compressFile=global.apiUrl+"compressFile/";
global.compressFileMeme=global.apiUrl+"compressFileMeme/";
global.deleteUploadMeme=global.apiUrl+"deleteUploadMeme/";
global.deletePost=global.apiUrl+"deletePost/";
global.saveVideo=global.apiUrl+"saveVideo/";
global.loadItems=global.apiUrl+"loadItems/";
global.publishMeme=global.apiUrl+"publishMeme/";
global.detailPost=global.apiUrl+"detailPost/";
global.loadComments=global.apiUrl+"loadComments/";
global.sendComment=global.apiUrl+"sendComment/";
global.like=global.apiUrl+"like/";
global.unlike=global.apiUrl+"unlike/";
global.subscribe=global.apiUrl+"subscribe/";
global.unsubscribe=global.apiUrl+"unsubscribe/";
global.mark=global.apiUrl+"mark/";
global.unmark=global.apiUrl+"unmark/";
global.commentLike=global.apiUrl+"commentLike/";
global.commentUnlike=global.apiUrl+"commentUnlike/";
global.commentReport=global.apiUrl+"commentReport/";
global.disablePost=global.apiUrl+"disablePost/";
global.publishPostToFacebook=global.apiUrl+"publishPostToFacebook/";
global.getPostSameDay=global.apiUrl+"getPostSameDay/";


global.commentUnreport=global.apiUrl+"commentUnreport/";
global.getNotif=global.apiUrl+"getNotif/";
global.readNotif=global.apiUrl+"readNotif/";
global.getTotalNotif=global.apiUrl+"getTotalNotif/";

global.getMemeGalery=global.apiUrl+"getMemeGalery/";
global.downloadMeme=global.apiUrl+"downloadMeme/";
global.getUserRank=global.apiUrl+"getUserRank/";


global.token=localStorage.getItem("token");
if(global.token===null)
    global.token="";
global.facebookAppId="1374733795998120";
global.googleAppId="213016135375-9jf4ikmmjvacj8dps5sae7mh4mdla4mn.apps.googleusercontent.com";
global.saveUserData=global.apiUrl+"saveUserData";
global.apiCreateAccount=global.apiUrl+"registerAccount";
global.apiLogin=global.apiUrl+"login";

global.checkTokenValidity=global.apiUrl+"checkTokenValidity";


const helpers = {
    findAndReplace: function(string, target, replacement){
        //var i = 0, length = string.length;
        try{
            for (let i=0; i < string.length; i++) {
        
                string = string.replace(target, replacement);
              
              }
        }catch(e) {
           
            //return {};
            alert("Maaf Ada kesalahan. Silahkan coba lagi")
          }
       
        
        return string;
    },

    getAvatar: function(string){
       
    },

    getLang:function(string){
        
            return string;
    }

}

export default helpers;

import React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Badge from '@material-ui/core/Badge';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import { fade } from '@material-ui/core/styles/colorManipulator';
import { withStyles } from '@material-ui/core/styles';
import AccountCircle from '@material-ui/icons/AccountCircle';
import NotificationsIcon from '@material-ui/icons/Notifications';
import MoreIcon from '@material-ui/icons/MoreVert';
import {  ArrowForward } from '@material-ui/icons';
import SwipeableTemporaryDrawer from './left.menu.js';
//import ResponsiveDialog from './upload.js';
import LoginDialog from '../login/login.dialog';
import { indigo, } from '@material-ui/core/colors';
import Avatar from '@material-ui/core/Avatar';
import {deepPurple} from '@material-ui/core/colors';
import { CircularProgress } from '@material-ui/core';
import {Refresh} from '@material-ui/icons';
import '../global';
import Button from '@material-ui/core/Button';
import { Link } from "react-router-dom";
//import { AddAPhoto } from '@material-ui/icons';
//import { addPost } from "../redux/action.js";
import './header.css';
import { FaUpload } from 'react-icons/fa';
import Favicon from 'react-favicon';
import LocalizedStrings from 'react-localization';

import HideOnScroll from "./HideOnScroll";

 
var lang = new LocalizedStrings({
 
 id: {
    upload_create:"Unggah/Buat Meme",
    logout:"Keluar",
    notification:"Notifikasi",
    login:"Login",
    error:"Maaf Ada Kesalahan. Silahkan coba beberapa saat lagi.",
    not_connected_to_server:"Maaf Anda tidak terhubung dengan server kami. Silahkan coba beberapa saat lagi.",
    see_more:"Lihat Lainnya...",
    no_more_items:"Tidak ada lagi",
    loading_please_wait:"Loading .... Mohon Tunggu Sebentar"
 },en:{
    upload_create:"Upload/Create Meme",
    logout:"Logout",
    notification:"Notification",
    login:"Login",
    error:"Error detected. Please try again.",
    not_connected_to_server:"Networks Problems. Please try again in a few minutes.",
    see_more:"See More...",
    no_more_items:"No more items",
    loading_please_wait:"Loading .... Please wait"
  }
});


const styles = theme => ({
  root: {
    width: '100%'

  },
  loadMoreComment:{
    fontSize:10,  
  },
  avatarNotif: {
    backgroundColor: deepPurple[500],
    width: 30,
    height: 30,
    fontSize:12,
    float:'left',
    marginRight:5
  },
  notif:{
      fontSize:12,
      textDecoration:'none',
      marginLeft:0,    
      marginBottom:1
  },

  notifUnread:{
    fontSize:12,
      textDecoration:'none',
      marginLeft:0,
      backgroundColor:'#edf2fa',
      marginBottom:1
    
  },
  noUnderline:{
    textDecoration:'none',
  },
  header:{
    backgroundColor:indigo[600]
  },
  toolbar:{
      minHeight:30
  },
  grow: {
    flexGrow: 1,
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing.unit * 2,
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing.unit * 3,
      width: 'auto',
    },
  },
  searchIcon: {
    width: theme.spacing.unit * 9,
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    paddingTop: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit * 10,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: 200,
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});



class PrimarySearchAppBar extends React.Component {

  constructor(props) {
      super(props);
      this.state = { 
        token:global.token,
        anchorEl: null,
        mobileMoreAnchorEl: null,
        notifMenu:null,
        notif:[],
        lastNotifId:0,
        hasMoreItems:true,
        loadingNotif:false,
        totalNotif:0
      };


      this.loadMoreNotification = this.loadMoreNotification.bind(this);
      
  }

  componentDidMount() {
    var language=localStorage.getItem('language');
    if(language==='en')
      lang.setLanguage('en');
    else
      lang.setLanguage('id');

    this.timeout =  setTimeout(() => {
      this.getTotalNotif();;
    }, 3000);

    //this.getTotalNotif();
    this.interval = setInterval(() => this.getTotalNotif(), 25000);
  }

 

  componentWillUnmount() {
    clearInterval(this.interval);
    clearTimeout(this.timeout);
  }

  getTotalNotif(){
    
    var self=this;
    var url = global.getTotalNotif;
    const formData=new FormData();
    formData.append("token", this.state.token);
    fetch(url,{ 
      method: 'POST', 
      body:formData
      }).then((response) => response.json())
      .then((responseJson) => {
         //console.log(responseJson.data);
         self.setState({
            totalNotif: responseJson.total
        });
         
      }).catch(function() {
       
      });

  }

  handleProfileMenuOpen = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleNotificationMenuOpen= event => {
    this.setState({ notifMenu: event.currentTarget,totalNotif:0 });
    //if(this.state.notif.length===0){
      this.getNotif(true);
    //}
      
  };
  loadMoreNotification(){
    this.getNotif(false);
  }

  getNotif(start=false){
    var url = global.getNotif;
    const formData=new FormData();
    formData.append("token", this.state.token);
    if(!start)
      formData.append("lastNotifId", this.state.lastNotifId);

    //let username=global.apiUser;
    //let password=global.apiPassword;
    //let auth = new Buffer(username + ":" + password).toString("base64");

    var self=this;
    self.setState({
        loadingNotif: true
    });
    var notifArr;

    try{
      fetch(url,{ 
      method: 'POST', 
      body:formData
      }).then((response) => response.json())
      .then((responseJson) => {
         //console.log(responseJson.data);
          if(responseJson) {

            if(start){
              notifArr=responseJson.data;
            }else{
              notifArr=this.state.notif;
              responseJson.data.map((notif) => {
                notifArr.push(notif);
                  //alert(post.POSTID);
                  return false;
              });
            }
              

              self.setState({
                  notif: notifArr,
                  loadingNotif: false
              });

              if(responseJson.lastNotifId !==0) {
                self.setState({
                  lastNotifId: responseJson.lastNotifId,
                  hasMoreItems: true
                });
              } else {
                  self.setState({
                      hasMoreItems: false
                  });
              }

          }
          
      }).catch(function() {
          alert(lang.error);
        self.setState({
          loadingNotif: false
        });

      });

      
    } catch(e) {
        alert(lang.not_connected_to_server);
        self.setState({
          loadingNotif: false
        });
    }

  }

  handleNotificationMenuClose = () => {
    this.setState({ notifMenu: null });
  };

  handleMenuClose = () => {
    this.setState({ anchorEl: null });
    this.handleMobileMenuClose();
  };

  handleMobileMenuOpen = event => {
    this.setState({ mobileMoreAnchorEl: event.currentTarget });
  };

  handleMobileMenuClose = () => {
    this.setState({ mobileMoreAnchorEl: null });
  };

  handleClickOpen(){
    alert('hai')
  }
  
  logout(){
   
    localStorage.setItem('token',"");
    localStorage.setItem('fullname',"");
    localStorage.setItem('email',"");
    localStorage.setItem('imageurl',"");
    localStorage.setItem('address',"");
    localStorage.setItem('phone',"");
    localStorage.setItem('gender',"");
    localStorage.setItem('userid',"");
    localStorage.setItem('usergroupid',"");
    window.location.href = global.baseUrl;
  }

  readNotif(notifId,link){
    

    var url = global.readNotif;
    const formData=new FormData();
    formData.append("token", this.state.token);
    formData.append("notifId", notifId);

    var self=this;
    self.setState({
        loadingNotif: true
    });

      fetch(url,{ 
      method: 'POST', 
      body:formData
      }).then((response) => response.json())
      .then((responseJson) => {
         //console.log(responseJson.data);
         self.setState({
            loadingNotif: false
        });
         window.location.href =link;
          
          
      }).catch(function() {
        alert(lang.error);
         self.setState({
            loadingNotif: false
        });
      });

  }

  switchToBahasa(){
    localStorage.setItem('language','id');
    window.location.reload(); 
  }

  switchToEnglish(){
    localStorage.setItem('language','en');
    window.location.reload(); 
  }

  reload(){
    window.location.reload();
  }

  
  render() {
    const { anchorEl, mobileMoreAnchorEl,notifMenu } = this.state;
    const { classes } = this.props;
    const isMenuOpen = Boolean(anchorEl);
    const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
    const isNotifMenuOpen= Boolean(notifMenu);
    
    const self=this;
    
 
      var renderNotifMenu = (
      
          !this.state.loadingNotif?
          <Menu
              anchorEl={notifMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={isNotifMenuOpen}
              onClose={this.handleNotificationMenuClose}
            >
            {this.state.notif.map(function(item, i){
              
              
              
              return <MenuItem onClick={()=>self.readNotif(item.NOTIFICATIONID,item.LINK)} key={"NOTIF"+item.NOTIFICATIONID} className={(item.ISREAD==='N')?classes.notifUnread:classes.notif}>
              {(item.IMAGEURL!=="" && item.IMAGEURL!==null && item.IMAGEURL!==undefined)?
                <Avatar src={item.IMAGEURL} aria-label="Avatar" className={classes.avatarNotif}>
                    {item.SENDERUSERNAME.charAt(0)}
                </Avatar>:
                <Avatar aria-label="Avatar" className={classes.avatarNotif}>
                    {item.SENDERUSERNAME.charAt(0)}
                </Avatar>
              } {item.MESSAGE}
            </MenuItem>
           
           })}

            {this.state.hasMoreItems?
            <MenuItem >
              <Button style={{textAlign:"center",margin:"auto",fontSize:10}} onClick={this.loadMoreNotification} size="small" variant="contained" color="primary" className={classes.loadMoreComment}>
                  {lang.see_more}
                  <Refresh size="small"  className={classes.loadMoreIcon}></Refresh>
                </Button>
            </MenuItem>: <div style={{textAlign:"center",margin:"auto",fontSize:12}}>--{lang.no_more_items}--</div>}

           </Menu>:<Menu
              anchorEl={notifMenu}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{ vertical: 'top', horizontal: 'left' }}
              open={isNotifMenuOpen}
              onClose={this.handleNotificationMenuClose}
            >
            {this.state.notif.map(function(item, i){
              
              
              
              return <MenuItem onClick={()=>self.readNotif(item.NOTIFICATIONID,item.LINK)} key={"NOTIF"+item.NOTIFICATIONID} className={(item.ISREAD==='N')?classes.notifUnread:classes.notif}>
              {(item.IMAGEURL!=="" && item.IMAGEURL!==null && item.IMAGEURL!==undefined)?
                <Avatar src={item.IMAGEURL} aria-label="Avatar" className={classes.avatarNotif}>
                    {item.SENDERUSERNAME.charAt(0)}
                </Avatar>:
                <Avatar aria-label="Avatar" className={classes.avatarNotif}>
                    {item.SENDERUSERNAME.charAt(0)}
                </Avatar>
              } {item.MESSAGE}
            </MenuItem>
           })}
            <MenuItem style={{fontSize:12}}>
              <CircularProgress></CircularProgress> {lang.loading_please_wait}
            </MenuItem>
  
           </Menu>
      );
    
    

    const renderMenu = (
      
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMenuOpen}
        onClose={this.handleMenuClose}
      >
        {/*<MenuItem onClick={this.handleMenuClose}>Profil</MenuItem>
        */
        }
        <MenuItem onClick={this.logout}>{lang.logout}</MenuItem>
        
      </Menu>
      
    );

    const renderMobileMenu = (
      <Menu
        anchorEl={mobileMoreAnchorEl}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        open={isMobileMenuOpen}
        onClose={this.handleMobileMenuClose}
      >
        {this.props.isLogin? 
        <div>
          <MenuItem onClick={this.handleNotificationMenuOpen}>
          <IconButton color="inherit" >
            <Badge badgeContent={this.state.totalNotif} max={999} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
          <p>{lang.notification}</p>
        </MenuItem>
        {/*<MenuItem onClick={this.handleProfileMenuOpen}>
          <IconButton color="inherit">
            <AccountCircle />
          </IconButton>
          <p>Profil</p>
        </MenuItem>
        */ }
        <MenuItem onClick={this.logout}>
          <IconButton color="inherit">
            <ArrowForward />
          </IconButton>
          <p>{lang.logout}</p>
        </MenuItem></div>:
        <MenuItem>
          <IconButton color="inherit">
            <ArrowForward />
          </IconButton>
          <p>{lang.login}</p>
        </MenuItem>
       
      }
      </Menu>
    );

    

    return (
      <div className={classes.root}>
        <Favicon url={global.imageUrl+'favicon/favicon.ico'} />
        <HideOnScroll threshold={200}>
        <AppBar position="fixed" className={classes.header}>
          <Toolbar className={classes.toolbar} style={{paddingLeft:0,paddingRight:0}}>
            <SwipeableTemporaryDrawer  isLogin={this.props.isLogin}></SwipeableTemporaryDrawer>
           
            <Typography  variant="h6" color="inherit" noWrap>
              <Link  title="Home" alt="Home" to="/" style={{textDecoration:"none",color:"#fed428"}}>
              <img alt="Logo Ketiwi" title="Logo Ketiwi" src={global.imageUrl+'LogoKetiwi.png'} style={{height:25,cursor:"pointer",verticalAlign:"middle",marginTop:0}}></img>
              </Link>
            </Typography>
            {/*<div className={classes.search}>
              <div className={classes.searchIcon}>
                <SearchIcon />
              </div>
              <InputBase
                placeholder="Search…"
                classes={{
                  root: classes.inputRoot,
                  input: classes.inputInput,
                }}
              />
              </div>*/}
            <div className={classes.grow} />
            <div className={classes.sectionDesktop}>

                
                <IconButton onClick={this.reload} color="inherit" title="Reload" style={{fontFamily:"Impact, Charcoal, sans-serif",fontSize:17,fontWeight:100,textDecoration:"none !important"}}>
                    <Refresh></Refresh>
                </IconButton>
               

               
                <Link className="noUnderline" title="Upload/Buat Meme" to="/upload" name="Upload" style={{color:"#ffffff"}}>
                    <IconButton  color="inherit" style={{fontFamily:"Impact, Charcoal, sans-serif",fontSize:17,fontWeight:100,textDecoration:"none !important"}}>
                      <span className="upload">
                        {lang.upload_create}
                      </span>
                        
                        
                    </IconButton>
                </Link>
                  
            {this.props.isLogin? 
              <div>

              
              <IconButton 
                onClick={this.handleNotificationMenuOpen}
                color="inherit">
                <Badge badgeContent={this.state.totalNotif} max={999} color="secondary">
                  <NotificationsIcon />
                </Badge>
                
              </IconButton>
              
              
              <IconButton
                aria-owns={isMenuOpen ? 'material-appbar' : undefined}
                aria-haspopup="true"
                onClick={this.handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton> </div>:
                null}

            </div>
            <div className={classes.sectionMobile}>
               
                    
                <IconButton onClick={this.reload} color="inherit" title="Reload" style={{fontFamily:"Impact, Charcoal, sans-serif",fontSize:17,fontWeight:100,textDecoration:"none !important"}}>
                    <Refresh></Refresh>
                </IconButton>
                <Link to="/upload" title={lang.upload_create} style={{color:"#ffffff"}}>
                    <IconButton  color="inherit">
                    
                        <FaUpload />
                        
                    </IconButton>
                </Link>


                  {this.props.isLogin? 
                  <IconButton aria-haspopup="true" onClick={this.handleMobileMenuOpen} color="inherit">
                    <MoreIcon />
                  </IconButton>:
                  <LoginDialog isOpen={false}/>
                  }
               
              
            </div>
          </Toolbar>
          
        </AppBar>
        
        </HideOnScroll>
        
        {renderMenu}
        {renderMobileMenu}
        {renderNotifMenu}
      </div>
    );
  }
}

PrimarySearchAppBar.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(PrimarySearchAppBar);
import React, { Component } from 'react';
import loadable from '@loadable/component';
import './App.css';
import { BrowserRouter as Router, Route,Switch } from "react-router-dom";
import { CircularProgress } from '@material-ui/core';
import PrimarySearchAppBar from './_header/header.js';
import Upload from './_header/upload.js';
//const PrimarySearchAppBar = loadable(() => import('./_header/header.js'));
//import Home from './home/home';
const Home = loadable(() => import('./home/home'));
//import Detail from './detail/detail';
//const Detail = loadable(() => import('./detail/detail'));
//import Register from './register/register';
const Register = loadable(() => import('./register/register'));


//const Upload = loadable(() => import('./_header/upload.js'));
//import Create from './create/create';
const Create = loadable(() => import('./create/create'));
//import UserRank from './userrank/userrank';
const UserRank = loadable(() => import('./userrank/userrank'));
//import { Provider } from "react-redux";

//import store from "./redux/store.js";
//import {addPost} from "./redux/action.js";

/*function register() {
  return (
    <Register></Register>
  );
}

function home() {
  return (
    <Home ></Home>
  );
}*/


class App extends Component {
  constructor(props) {
    super(props);
    // create a ref to store the textInput DOM element
    this.state = { 
      loading: true,
      isLogin:false
    };
    this.home = this.home.bind(this);
    this.register = this.register.bind(this);
    this.liked = this.liked.bind(this);
    this.marked = this.marked.bind(this);
    this.myuploaded = this.myuploaded.bind(this);
    this.trending = this.trending.bind(this);
    this.detail = this.detail.bind(this);
    this.upload = this.upload.bind(this);
    this.create = this.create.bind(this);
    this.contact = this.contact.bind(this);
    this.userrank = this.userrank.bind(this);
    this.subscriptions = this.subscriptions.bind(this);
    this.from = this.from.bind(this);
    
  }

  componentDidMount(){
    var token=localStorage.getItem('token');
    if(token!==null && token!=="" && token!==undefined){
      this.checkTokenValidity();

     
    }else{
      this.setState({
        loading: false
      });
    }
   
  }

  home({ match }) {
    return (
      <div >
          <PrimarySearchAppBar lang={match.lang} isLogin={this.state.isLogin} className="header"></PrimarySearchAppBar>
          <Home  isLogin={this.state.isLogin} query="home"></Home>
      </div>
    );
  }

  upload() {
    return (
      <div>
         
          <Upload isLogin={this.state.isLogin} query="upload"></Upload>
      </div>
    );
  }

  create() {
    return (
      <div>
         
          <Create isLogin={this.state.isLogin} query="upload"></Create>
      </div>
    );
  }

  register() {
    return (
      <div>
          <PrimarySearchAppBar isLogin={this.state.isLogin} className="header"></PrimarySearchAppBar>
          <Register isLogin={this.state.isLogin} ></Register>
      </div>
    );
  }

  liked() {
    return (
      <div>
          <PrimarySearchAppBar isLogin={this.state.isLogin} className="header"></PrimarySearchAppBar>
          <Home isLogin={this.state.isLogin} query="liked"></Home>
      </div>
    );
  }

  marked() {
    return (
      <div>
          <PrimarySearchAppBar isLogin={this.state.isLogin} className="header"></PrimarySearchAppBar>
          <Home isLogin={this.state.isLogin} query="marked"></Home>
      </div>
    );
  }

  myuploaded() {
    return (
      <div>
          <PrimarySearchAppBar isLogin={this.state.isLogin} className="header"></PrimarySearchAppBar>
          <Home isLogin={this.state.isLogin} query="myuploaded"></Home>
      </div>
    );
  }

  subscriptions() {
    return (
      <div>
          <PrimarySearchAppBar isLogin={this.state.isLogin} className="header"></PrimarySearchAppBar>
          <Home isLogin={this.state.isLogin} query="subscriptions"></Home>
      </div>
    );
  }

  trending() {
    return (
      <div>
        <PrimarySearchAppBar isLogin={this.state.isLogin} className="header"></PrimarySearchAppBar>
          <Home isLogin={this.state.isLogin} query="trending"></Home>
      </div>
    );
  }

  contact() {
    return (
      <div>
        <PrimarySearchAppBar isLogin={this.state.isLogin} className="header"></PrimarySearchAppBar>
          <div style={{marginTop:100,textAlign:"center"}}>
                Jika Anda memiliki pertanyaan, saran, maupun kritik untuk situs ini silahkan hubungi Kami melalui email:<br></br><br></br>
                <i>Please contact us by sending email to:</i>
                <h2><a href="mailto:info@ketiwi.com">info@ketiwi.com</a></h2>
          </div>
      </div>
    );
  }

  detail({ match }) {
    return (
      <div>
          <PrimarySearchAppBar isLogin={this.state.isLogin} className="header"></PrimarySearchAppBar>
          <Home isLogin={this.state.isLogin} postId={match.params.id} showDetail="yes"></Home>
      </div>
    );
  }

  from({ match }) {
    return (
      <div>
          <PrimarySearchAppBar isLogin={this.state.isLogin} className="header"></PrimarySearchAppBar>
          <Home isLogin={this.state.isLogin} query="from" userId={match.params.id}></Home>
      </div>
    );
  }


  userrank(){
    return (
      <div>
        
          <PrimarySearchAppBar isLogin={this.state.isLogin} className="header"></PrimarySearchAppBar>
          <UserRank isLogin={this.state.isLogin}></UserRank>
      </div>
    );
  }

  checkTokenValidity(){
    
    //alert('hao');
    var token=localStorage.getItem('token');

    if(token!==null && token!=="" && token!==undefined){

      var url=global.checkTokenValidity;
      const formData=new FormData();
      formData.append("token",token);
  
      //console.log(formData);
  
      // let username=global.apiUser;
      // let password=global.apiPassword;
      //let auth = new Buffer(username + ":" + password).toString("base64");


      try{
        fetch(url,{ 
            method: 'POST', 
            body:formData
          }).then((response) => response.json())
            .then((responseJson) => {
              
              
              if(responseJson.status==='valid'){
                localStorage.setItem('username',responseJson.username);
                localStorage.setItem('fullname',responseJson.fullname);
                localStorage.setItem('email',responseJson.email);
                localStorage.setItem('imageurl',responseJson.imageurl);
                localStorage.setItem('address',responseJson.address);
                localStorage.setItem('phone',responseJson.phone);
                localStorage.setItem('gender',responseJson.gender);
                localStorage.setItem('userid',responseJson.userid);
                localStorage.setItem('usergroupid',responseJson.usergroupid);

                this.setState({
                  isLogin: true,
                  loading: false
                });

              }else{
                localStorage.setItem('token',"");
                localStorage.setItem('fullname',"");
                localStorage.setItem('email',"");
                localStorage.setItem('imageurl',"");
                localStorage.setItem('address',"");
                localStorage.setItem('phone',"");
                localStorage.setItem('gender',"");
                localStorage.setItem('userid',"");
                this.setState({
                  loading: false
                });
              }
             // alert (localStorage.getItem('token'));
               
            })
    
            
        } catch(e) {
          this.setState({
            loading: false
          });
       
          alert("Maaf Anda tidak terhubung dengan server kami. Silahkan coba beberapa saat lagi.")
        }
    }
   
  }

  render() {
   
    if(this.state.loading){
      return (
        <div style={{width:"100%",margin:"auto",textAlign:"center"}}><CircularProgress></CircularProgress></div>
      );
    }else{
      return (
        
        <Router>
          <div>
          
          <Switch>
            <Route exact path="/" component={this.home} />
            <Route path="/contact" component={this.contact} />
            <Route path="/upload" component={this.upload} />
            <Route path="/create" component={this.create} />
            <Route path="/liked" component={this.liked} />
            <Route path="/marked" component={this.marked} />
            <Route path="/subscriptions" component={this.subscriptions} />
            <Route path="/myuploaded" component={this.myuploaded} />
            <Route path="/trending" component={this.trending} />
            <Route path="/register" component={this.register} />
            <Route path="/userrank" component={this.userrank} />
            <Route path="/detail/:id/:permalink" component={this.detail} />
            <Route path="/from/:id" component={this.from} />
          </Switch>
          </div>
      </Router>
     );
    }
    
    
  }
}

export default App;

import React from "react";

function getScrollY(scroller) {
  return scroller.pageYOffset !== undefined
    ? scroller.pageYOffset
    : scroller.scrollTop !== undefined
    ? scroller.scrollTop
    : (document.documentElement || document.body.parentNode || document.body)
        .scrollTop;
}

const useAppBarScroll = options => {
  const { threshold, scroller, directional = true } = options;

  const scrollRef = React.useRef();
  const [trigger, settrigger] = React.useState(false);

  const handleScroll = React.useCallback(() => {
    const scrollY = getScrollY(scroller || window);
    const prevScrollY = scrollRef.current;
    scrollRef.current = scrollY;

    if (directional) {
      settrigger(
        scrollY < prevScrollY
          ? false
          : scrollY > prevScrollY &&
            scrollY > (threshold != null ? threshold : 100)
          ? true
          : false
      );
    } else {
      settrigger(scrollY > (threshold != null ? threshold : 100));
    }
  }, [scroller, threshold]);

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [handleScroll]);

  return trigger;
};

export default useAppBarScroll;

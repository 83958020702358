import React from 'react';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { withStyles } from '@material-ui/core/styles';
import { FilePond } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import TextField from '@material-ui/core/TextField';
import '../global';
import { Player, BigPlayButton, LoadingSpinner } from 'video-react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { FaSave } from 'react-icons/fa';
import Checkbox from '@material-ui/core/Checkbox';
import {
    withRouter
  } from 'react-router-dom'
  
import { Link } from "react-router-dom";
import './upload.css';
import Login from '../login/login';

import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import ProhibitedContent from './prohibited.content.js';
import Agreement from './agreement.js';
import LocalizedStrings from 'react-localization';
 
var lang = new LocalizedStrings({
 
 id: {
    create_account:"Buat Akun",
    upload_image_video:"Unggah Gambar / video",
    create_meme:"Buat Meme",
    close:"Tutup",
    save:"Simpan",
    image_video:"Gambar / Video",
    title:"Judul",
    caption:"Keterangan (Opsional)",
    source:"Sumber (url jika konten diambil dari situs lain)",
    mine:"Konten ini buatan Saya sendiri",
    agreement_text:"Saya telah membaca, memahami, dan setuju terhadap ketentuan unggah konten dalam situs ketiwi.com yang tertera pada link berikut ",
    agreement:"Persetujuan",
    please_login:"Silahkan Login",
    please_read:"Anda harus membaca halaman persetujuan, kemudian beri tanda Checklist pada bagian persetujuan jika Anda setuju terhadap ketentuan unggah konten pada situs ketiwi.com !",
    please_upload:"Silahkan Unggah Video/Gambar!",
    image_not_valid:"Video / Gambar tidak valid. Silahkan unggah video/gambar lain!",
    please_enter_title:"Silahkan Masukan Judul",
    error:"Maaf Ada Kesalahan. Silahkan coba beberapa saat lagi.",
    not_connected_to_server:"Maaf Anda tidak terhubung dengan server kami. Silahkan coba beberapa saat lagi.",
},en:{
    create_account:"Create Account",
    upload_image_video:"Upload Image / Video",
    create_meme:"Create Meme",
    close:"Close",
    save:"Save",
    image_video:"Image / Video",
    title:"Title",
    caption:"Caption (Optional)",
    source:"Source (if the content is taken from another site)",
    mine:"This content is made by myself",
    agreement_text:"I have read, understood, and agreed to the conditions for uploading content on the ketiwi.com website listed in the following link ",
    agreement:"Agreement",
    please_login:"Please Login",
    please_read:"You must read the approval page, then mark the Checklist in the approval section if you agree to the conditions for uploading content on the ketiwi.com site!",
    please_upload:"Please upload video / image !",
    image_not_valid:"Not valid image/video",
    please_enter_title:"Please enter title",
    error:"Error detected. Please try again.",
    not_connected_to_server:"Networks Problems. Please try again in a few minutes.",
}
});

//import { Redirect } from 'react-router-dom';

const styles = {
    appBar: {
      position: 'relative',
    },
    toolbar:{
        minHeight:30
    },
    flex: {
      flex: 1,
    },
    content:{
        padding:15,
        marginTop:10
    },
    textField:{
        width:'100%'
    },
    textArea:{
        width:'100%',
        height:200
    },
    videoSize:{
        maxWidth:360,
        margin:'auto'
    },
    loadingWhite:{
        color:'#fff'
    }
  };


class Upload extends React.Component {

    constructor(props) {
        super(props);
        this.pond = React.createRef();

        var userGroupId=localStorage.getItem('usergroupid');
       
        this.state = { 
            isLogin: this.props.isLogin,
            token:global.token,
            open: false,
            userId: this.props.userId,
            fileUrl:null,
            thumbUrl:null,
            fileType:null,
            postId:null,
            loadingMedia:false,
            loadingSave:false,
            toHome:false,
            title:"",
            mine:null,
            agree:false,
            caption:"",
            userGroupId:userGroupId
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.compressFile = this.compressFile.bind(this);
        this.agree = this.agree.bind(this);
        this.notAgree = this.notAgree.bind(this);
        this.handleAgree = this.handleAgree.bind(this);
    }
    componentDidMount(){
        //alert(this.state.userGroupId);
    }

    handleAgree(event) {
        const target = event.target;
        this.setState({agree: target.checked});
    }

    agree(){
        this.setState({agree: true,open:false});
    }

    notAgree(){
        this.setState({agree: false,open:false});
    }

    hideLoadingMedia(){
        this.setState({loadingMedia: false});
    }

    handleClickOpen() {
        if(this.state.token===""){
            alert(lang.please_login);
        }else{
            this.setState({open: true});
        }
        //alert('hai');
        
    }

    handleClose() {
        this.setState({open: false});
    }

    Transition(props) {
        return <Slide direction="up" {...props} />;
    }

    clearForm(){
        this.setState ({ 
         
            fileUrl:null,
            thumbUrl:null,
            fileType:null,
            loadingMedia:false,
            loadingSave:false,
            title:"",
            caption:"",
            toHome:true,
            open:false
        });
    }


    save(){
        //alert(this.state.mine);
        if(!this.state.agree)
            alert(lang.please_read);
        else
        if(this.state.fileUrl==="" || this.state.fileUrl===null)
            alert(lang.please_upload);
        else
        if(this.state.postId==="" || this.state.postId===null)
            alert(lang.image_not_valid);
     
        else if(this.state.title==="" || this.state.title===null)
            alert(lang.please_enter_title);
        else{
            //save video
            //show loading
            this.setState({loadingSave: true});

            const formData=new FormData();
            formData.append("sourceLink", this.state.sourceLink);
            formData.append("mine", this.state.mine);
            formData.append("postId", this.state.postId);
            formData.append("token", this.state.token);
            formData.append("title", this.state.title);
            formData.append("caption", this.state.caption);
            formData.append("fileUrl", this.state.fileUrl);
            formData.append("thumbUrl", this.state.thumbUrl);
            formData.append("fileType", this.state.fileType);
            var url=global.saveVideo;
            //let username=global.apiUser;
            //let password=global.apiPassword;
            //let auth = new Buffer(username + ":" + password).toString("base64");
           
            try{
                fetch(url,{ 
                method: 'POST', 
                body:formData
                }).then((response) => response.json())
                .then((responseJson) => {
                   
                    
                    if(responseJson.status===1){
                        
                       
                        this.clearForm();
                        //tambah data ke home
                        //alert(responseJson.message);
                       //window.location.reload();
                        //this.props.history.push('/');
                       // this.setState({loadingSave: false,toHome:true,open:false});
                        this.props.history.push('/');
                        
                    }else{
                        alert(responseJson.message);
                        this.setState({loadingSave: false});
                    }
                    
                }).catch(function() {
                    alert(lang.error);
                    this.setState({loadingSave: false});
                  });
        
        
                
            } catch(e) {
                this.setState({loadingSave: false});
                //return {};
                alert(lang.not_connected_to_server)
            }

        }
        

    }

    create(){
        this.props.history.push('/create');
    }

    compressFile(fileUrl,fileType){
        const formData=new FormData();
            formData.append("token", this.state.token);
            formData.append("fileUrl", fileUrl);
            formData.append("fileType", fileType);
            var url=global.compressFile;
            
            try{
                fetch(url,{ 
                method: 'POST', 
                body:formData
                }).then((response) => response.json())
                .then((responseJson) => {
                  
                    
                }).catch(function() {
                    //alert("Maaf Ada Kesalahan. Silahkan coba beberapa saat lagi.");
                   
                });
        
        
                
            } catch(e) {
                //this.setState({loadingSave: false});
                //return {};
                //alert("Maaf Anda tidak terhubung dengan server kami. Silahkan coba beberapa saat lagi.")
            }
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.name : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }

      handleClick2(){
          alert('hai');
          return false;
      }

    render() {

        var language=localStorage.getItem('language');
        if(language==='en')
          lang.setLanguage('en');
        else
          lang.setLanguage('id');
          
       
        const { classes } = this.props;
        //let username=global.apiUser;
        //let password=global.apiPassword;
        //const auth = new Buffer(username + ":" + password).toString("base64");
        
        return (
            this.state.isLogin?
            <div>


              
                <AppBar position="fixed" className={classes.header}>
                    <Toolbar className={classes.toolbar}>
                        <Link to="/" style={{color:"#ffffff"}}>
                                <IconButton color="inherit" aria-label="Close">
                                <CloseIcon />
                                </IconButton>
                        </Link>  
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                        {lang.close}
                        </Typography>
                   
                        {this.state.loadingSave?
                       <CircularProgress color="secondary" />
                        :
                        <Button color="inherit" onClick={() =>this.save()}>
                        <FaSave></FaSave>

                          {lang.save}
                        </Button>
                        }
                    </Toolbar>
                </AppBar>

                
            <Paper square style={{marginTop:45}}>
                <Tabs
                value={0}
                indicatorColor="primary"
                textColor="primary"
                centered
                >
                <Tab label={lang.upload_image_video} />
                <Tab label={lang.create_meme} onClick={() =>this.create()}/>
                </Tabs>
            </Paper>
                    
                <div className={classes.content}>

                    

                    <div className="leftUpload">
                            {lang.image_video}
                            
                            <FilePond
                                
                                server={ {
                                    url: global.apiUrl,
                                    process: {
                                        url: './upload',
                                        method: 'POST',
                                    
                                        ondata: (formData) => {
                                            formData.append('token', this.state.token);
                                            return formData;
                                        },
                                        onerror: (response) => {
                                            let data=JSON.parse(response);
                                            alert(data.message);
                                        },
                                        onload: (response) => {
                                            let data=JSON.parse(response);
                                            //alert(data.filetype);
                                            if(data.filetype==='video'){
                                                
                                                this.setState({thumbUrl: data.thumburl,fileUrl:data.fileurl,postId:data.postid,fileType:'video',loadingMedia:true});      
                                                this.compressFile(data.fileurl,data.filetype);
                                            }else if(data.filetype==='gif'){
                                                
                                                this.setState({fileUrl:data.fileurl,fileType:'gif',loadingMedia:true,postId:data.postid,});   
                                            
                                            }else{
                                                
                                                this.setState({fileUrl:data.fileurl,fileType:'image',loadingMedia:true,postId:data.postid,});   
                                                this.compressFile(data.fileurl,data.filetype);
                                            }

                                            

                                            
                                            //alert(data.fileurl);
                                        }
                                    },
                                    revert: {
                                        url: './cancelUpload?postId='+this.state.postId+"&fileUrl="+this.state.fileUrl,
                                        method: 'POST',
                                        ondata: (formData) => {
                                            formData.append('token', this.state.token);
                                            formData.append('postId', this.state.postId);
                                            return formData;
                                        }
                                    },
                                // revert: './cancelUpload/',
                                    restore: './restoreUpload/',
                                    load: './load/',
                                    fetch: './fetch/'
                                }}
                                onremovefile={(fileItems) => {
                                    // Set current file objects to this.state
                                    this.setState({thumbUrl:null,fileUrl:null,fileType:null}); 
                                }}>
                                
                                

                            </FilePond>
                            <br></br><br></br>

                            
                            <div className={classes.videoSize}>
                            

                                {this.state.fileType==='video'?
                                    <Player 
                                    playsInline
                                    poster={global.videoUrl+this.state.thumbUrl}
                                    src={global.videoUrl+this.state.fileUrl}
                                    ref="player"
                                    
                                    >
                                    <BigPlayButton position="center" />
                                    <LoadingSpinner />
                                    
                                    </Player>
                                :null
                                }
                                

                                {this.state.fileType==='image' || this.state.fileType==='gif'?
                                    <div>
                                    {this.state.loadingMedia?
                                    <CircularProgress className={classes.progress} />
                                    :null}
                                    <img alt="imageUpload" onLoad={this.hideLoadingMedia.bind(this)} className={classes.videoSize} src={global.videoUrl+this.state.fileUrl} />
                                    </div>
                                :null
                                }
                            </div>

                            <Divider />
                            <TextField
                                    id="outlined-uncontrolled"
                                    label={lang.title}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    name="title"
                                    value={this.state.title}
                                    onChange={this.handleInputChange}
                            />

                            <TextField
                                    id="outlined-multiline-flexible"
                                    label={lang.caption}
                                    multiline={true}
                                    rows={6}
                                    rowsMax={10}
                                    className={classes.textArea}
                                    margin="normal"
                                    variant="outlined"
                                    name="caption"
                                    value={this.state.caption}
                                    onChange={this.handleInputChange}
                                />
                            <div style={{marginTop:-50}}>
                            <TextField
                                    id="outlined-uncontrolled"
                                    label={lang.source}
                                    className={classes.textField}
                                    margin="normal"
                                    variant="outlined"
                                    name="sourceLink"
                                    value={this.state.sourceLink}
                                    onChange={this.handleInputChange}
                            />

                            <Checkbox 
                                        
                                        name="mine"
                                        onChange={this.handleInputChange}
                                        value="mine"
                                        color="primary"
                                    /> {lang.mine}
                            </div>
                            <div style={{marginTop:-20}}>
                            <Checkbox 
                                        checked={this.state.agree}
                                        name="agree"
                                        onChange={this.handleAgree}
                                        value="agree"
                                        color="primary"
                                    /> {lang.agreement_text} <Button color="primary" onClick={this.handleClickOpen}>{lang.agreement}</Button> 
                            
                            <Agreement
                                open={this.state.open}
                                onClose={this.handleClose}
                                agree={this.agree}
                                notAgree={this.notAgree}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                                >
                               
                            </Agreement>
                            
                            </div>
                        

                        <Divider></Divider>
                    </div>
                        
                    <div className="rightUpload" >
                        <ProhibitedContent></ProhibitedContent>
                    </div>    
                   
                </div>
                   
            </div>:
            <div style={{width:300,margin:'auto',marginTop:20}}>
                <AppBar position="fixed" className={classes.header} >
                    <Toolbar>
                        <Link to="/" style={{color:"#ffffff"}}>
                                <IconButton color="inherit" aria-label="Close">
                                <CloseIcon />
                                </IconButton>
                        </Link>  
                        <Typography variant="h6" color="inherit" className={classes.flex}>
                        {lang.close}
                        </Typography>
                   
                    </Toolbar>
                </AppBar>
                <div style={{marginTop:90}}>

                </div>
                <Login redirectHome={false}></Login>
                <Link to="/register">{lang.create_account}</Link><br></br> 
            </div>
            
        );
    }
}


 export default withRouter(withStyles(styles)(Upload));
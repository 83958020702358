import React from 'react';
import TextField from '@material-ui/core/TextField';
import { withStyles } from '@material-ui/core/styles';
import '../global';
import Button from  '@material-ui/core/Button';
import { IoLogoFacebook, IoLogoGoogle} from 'react-icons/io';
import { IoMdLogIn } from 'react-icons/io';
import { Divider, CircularProgress } from '@material-ui/core';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import GoogleLogin from 'react-google-login';
import red from '@material-ui/core/colors/red';

import LocalizedStrings from 'react-localization';
 
var lang = new LocalizedStrings({
 
 id: {
    create_account:"Buat Akun",
    error:"Maaf Ada Kesalahan. Silahkan coba beberapa saat lagi.",
    not_connected_to_server:"Maaf Anda tidak terhubung dengan server kami. Silahkan coba beberapa saat lagi.",
    login_failed:"Login Gagal, Silahkan coba lagi.",
    plase_enter_email:"Silahkan masukan Email",
    plase_enter_password:"Silahkan masukan Password",
   
 },en:{
    create_account:"Buat Akun",
    error:"Error detected. Please try again.",
    not_connected_to_server:"Networks Problems. Please try again in a few minutes.",
    login_failed:"Login Failed, Please Try Again.",
    plase_enter_email:"Please enter Your Email",
    plase_enter_password:"Please enter Your Password",
   
  }
});
const styles = {
    loginFacebook: {
        width: '100%',
        marginTop:5,
        marginBottom:5,
        backgroundColor:'#4267b2'
      },

      loginGoogle:{
        width: '100%',
        marginTop:5,
        marginBottom:5,
        backgroundColor: red[500],
      },
      logoFacebook :{
        fontSize: 20
      },
    appBar: {
      position: 'relative',
    },
    flex: {
      flex: 1,
    },
    content:{
        padding:15
    },
    textField:{
        width:'100%',
        paddingTop:1,
        marginTop:0
    },
    textArea:{
        width:'100%',
        height:200
    },
    videoSize:{
        maxWidth:360,
        margin:'auto'
    },
    loadingWhite:{
        color:'#fff'
    },
    buttonLogin:{
        backgroundColor:'#ff9800',
        width:'100%',
        marginTop:0,
        marginBottom:10
        
    }
  };

  
class Login extends React.Component {
    
    constructor(props) {
        super(props);
        this.state = { 
            emailLogin: "",
            passwordLogin: "",
            loading:false,
            redirectHome:this.props.redirectHome,
            loginData:[],
        };
        

        this.handleInputChange = this.handleInputChange.bind(this);
        this.responseFacebook = this.responseFacebook.bind(this);
        this.responseGoogle = this.responseGoogle.bind(this);
        this.responseGoogleFail = this.responseGoogleFail.bind(this);
        this.signIn = this.signIn.bind(this);
    }

    componentDidMount() {
      var language=localStorage.getItem('language');
      if(language==='en')
        lang.setLanguage('en');
      else
        lang.setLanguage('id');
  
    }

    responseFacebook = (response) => {
  

        if (response.accessToken !== '' && response.accessToken !== null && response.accessToken !== undefined) {
         // console.log(response);
          this.setState({
            loginData: response
          });
        
          //console.log(this.state.loginData.email);
          this.saveUserDataFacebook();
          
        } else {
          // type === 'cancel'
          alert(lang.login_failed)
        }
    
    }

    saveUserDataFacebook(){

        this.setState({
            loading: true
          });
    
        //save to ketiwi.com  
        var url=global.saveUserData;
        const formData=new FormData();
        formData.append("email", this.state.loginData.email);
        formData.append("gender", this.state.loginData.gender);
        formData.append("fullname", this.state.loginData.name);
        formData.append("oauthId", this.state.loginData.id);
        formData.append("firstName", this.state.loginData.first_name);
        formData.append("lastName", this.state.loginData.last_name);
        formData.append("link", this.state.loginData.link); 
        formData.append("locale", this.state.loginData.locale); 
        formData.append("imageUrl", this.state.loginData.picture.data.url); 
        formData.append("oauthProvider", "Facebook Web");
        formData.append("jsonData",JSON.stringify(this.state.loginData));
        //console.log(formData);
    
        try{
        fetch(url,{ 
            method: 'POST', 
            body:formData
          }).then((response) => response.json())
            .then((responseJson) => {
              this.setState({
                loading: false
              });
              
              if(responseJson.status==='success'){
                this.setState({
                  email: this.state.loginData.email
                });
                
                localStorage.setItem("token", responseJson.token);
                if(!this.state.redirectHome)
                   window.location.reload();
                else
                  window.location.href = global.baseUrl;
                //this.goToSearchBus();
              }else
                alert(responseJson.message);
            })
    
            
        } catch(e) {
          this.setState({
            loading: false
          });
       
          alert(lang.not_connected_to_server)
        }

    }
    
    responseGoogle = (response) => {
        if (response.accessToken !== '' && response.accessToken !== null && response.accessToken !== undefined) {
            // console.log(response);
             this.setState({
               loginData: response.profileObj
             });
           
             //console.log(this.state.loginData.email);
             this.saveUserDataGoogle();
             
           } else {
             // type === 'cancel'
             alert(lang.login_failed)
           }
        
        //alert('woi');
        console.log(response);
    }


    signIn  (){

        if(this.state.emailLogin==="")
          alert(lang.plase_enter_email);
        else if(this.state.passwordLogin==="")
          alert(lang.plase_enter_password)
        else{
          this.setState({
            loading: true
          });
          var url=global.apiLogin;
          const formData=new FormData();
          formData.append("email", this.state.emailLogin);
          formData.append("password", this.state.passwordLogin);
         
          try{
           fetch(url,{ 
              method: 'POST', 
              body:formData
                }).then((response) => response.json())
              .then((responseJson) => {
                this.setState({
                    loading: false
                  });
                  
                  if(responseJson.status==='success'){
                   
                    localStorage.setItem("token", responseJson.token);
                    if(!this.state.redirectHome)
                      window.location.reload();
                    else
                      window.location.href = global.baseUrl;
                    //this.goToSearchBus();
                  }else
                    alert(responseJson.message);
              })
      
              
          } catch(e) {
            this.setState({
              loading: false
            });
         
            alert(lang.not_connected_to_server)
          }
        }
    
        
      }

    saveUserDataGoogle(){
    
        //save to ketiwi.com  
        var url=global.saveUserData;
        const formData=new FormData();
        formData.append("email", this.state.loginData.email);
        formData.append("gender", this.state.loginData.gender);
        formData.append("fullname", this.state.loginData.name);
        formData.append("oauthId", this.state.loginData.googleId);
        formData.append("firstName", this.state.loginData.givenName);
        formData.append("lastName", this.state.loginData.familyName);
        formData.append("imageUrl", this.state.loginData.imageUrl); 
        formData.append("oauthProvider", "Google Web");
        formData.append("jsonData",JSON.stringify(this.state.loginData));
    
        //let username=global.apiUser;
        //let password=global.apiPassword;
        //let auth = new Buffer(username + ":" + password).toString("base64");
        try{
          fetch(url,{ 
            method: 'POST', 
            body:formData}).then((response) => response.json())
            .then((responseJson) => {
                this.setState({
                    loading: false
                  });
                  
                  if(responseJson.status==='success'){
                    this.setState({
                      email: this.state.loginData.email
                    });
                    
                    localStorage.setItem("token", responseJson.token);
                    if(!this.state.redirectHome)
                        window.location.reload();
                    else
                      window.location.href = global.baseUrl;
                    //this.goToSearchBus();
                  }else
                    alert(responseJson.message);
                })
    
            
        } catch(e) {
          this.setState({
            loading: false
          });
       
          alert(lang.not_connected_to_server)
        }
    
        /*setTimeout(() => {
          this.setState({
            loading: false
          });
        }, 2500);*/
      }
    
    responseGoogleFail = (response) => {
    //console.log(response);
    alert(lang.login_failed)
    }

    handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
    
        this.setState({
          [name]: value
        });
      }

  
    render() {
        const { classes } = this.props;
        if(this.state.loading){
            return (
                <CircularProgress> Loading ...</CircularProgress>
            )
        }
      return (

        <form  noValidate autoComplete="off">
            <TextField
             
                label="Username / Email"
                name="emailLogin"
                className={classes.textField}
                value={this.state.emailLogin}
                onChange={this.handleInputChange}
                margin="normal"
                variant="outlined"
            />
            <TextField
        
                label="Password"
                className={classes.textField}
                onChange={this.handleInputChange}
                value={this.state.passwordLogin}
                name="passwordLogin"
                type="password"
                margin="normal"
                variant="outlined"
            />
            <Button name="Login" variant="contained" onClick={this.signIn} color="primary" className={classes.buttonLogin}>
                Login &nbsp;
               <IoMdLogIn ></IoMdLogIn>
            </Button>
            <Divider></Divider>
                    <div>
                      </div>
                      <FacebookLogin
                        appId={global.facebookAppId}
                        autoLoad={false}
                        fields="name,first_name,last_name,email,link,gender,locale,cover,picture,address,age_range,birthday,devices,education,hometown,interested_in,location,religion,website,work"
                        callback={this.responseFacebook}
                        
                        icon="fa-facebook"
                        render={renderProps => (
                          <Button name="LoginFacebook" variant="contained" color="primary"  className={classes.loginFacebook}  onClick={renderProps.onClick}>
                            Login Facebook &nbsp;
                            <IoLogoFacebook  className={classes.logoFacebook}/>
                          </Button >
                        )}
                      />

                    <GoogleLogin
                        clientId={global.googleAppId}
                        clentSecret="6GT8VDEtmtIf9y5cT0hinRTm"
                        render={renderProps => (
                          <Button  name="LoginGoogle" className={classes.loginGoogle}
                          variant="contained" color="secondary" 
                          onClick={renderProps.onClick}>Login Google &nbsp;
                          <IoLogoGoogle  className={classes.logoFacebook}/>
                          </Button >
                        )}
                        buttonText="Login Google"
                        onSuccess={this.responseGoogle}
                        onFailure={this.responseGoogleFail}
                      />
                      
      </form>
      
      );
    }
  }

  
  export default  withStyles(styles)(Login);
  
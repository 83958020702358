import React from 'react';
import './upload.css';

class ProhibitedContent extends React.Component {

     /*constructor(props) {
        super(props);
       this.pond = React.createRef();
       
        this.state = { 
            isLogin: this.props.isLogin,
            token:global.token,
            open: false,
            userId: this.props.userId,
            fileUrl:null,
            thumbUrl:null,
            fileType:null,
            postId:null,
            loadingMedia:false,
            loadingSave:false,
            toHome:false,
            title:"",
            mine:null,
            agree:false,
            caption:""
        };
        this.handleInputChange = this.handleInputChange.bind(this);
        this.handleClickOpen = this.handleClickOpen.bind(this);
        this.handleClose = this.handleClose.bind(this);
        this.compressFile = this.compressFile.bind(this);
        this.agree = this.agree.bind(this);
        this.notAgree = this.notAgree.bind(this);
        this.handleAgree = this.handleAgree.bind(this);
    }*/
    componentDidMount(){
        
    }
    render() {

        return (
            <div style={{padding:5}}>
                <p><b>Konten Yang Dilarang</b> dalam situs ketiwi.com:</p>
                <ol>
                    <li>
                    Konten yang mengandung unsur SARA.
                    </li>
                    <li>
                    Konten yang mengandung unsur PORNOGRAFI.
                    </li>
                    <li>
                    Konten yang mengandung unsur HOAKS (penyebaran informasi bohong/tidak benar dan atau belum/tidak teruji kebenarannya).

                    </li>
                    <li>
                    Konten yang berbau politik, mendukung salah satu atau lebih suatu golongan/partai.

                    </li>
                    <li>
                    Konten dengan tujuan promosi/anjuran untuk mengunjungi situs lain yang serupa dengan ketiwi.com.

                    </li>
                    <li>
                    Konten yang berisi spam/junk file/materi yang tidak bermakna dan perulangan konten yang sudah ada sebelumnya.

                    </li>
                    <li>
                    Konten yang berisi tindak kekerasan dan atau disturbing picture.

                    </li>
                    <li>
                    Konten yang tidak ramah/pantas untuk ditunjukan terhadap anak dibawah umur.

                    </li>
                    <li>
                    Konten yang berisi perbandingan ketiwi.com dengan situs lain.

                    </li>
                    <li>
                    Konten yang berisi penyebaran kebencian, cacian, hinaan, makian, sumpah serapah terhadap suatu produk atau kelompok/individu/golongan/organisasi/komunitas tertentu.

                    </li>
                    <li>
                    Konten yang dilindungi hak cipta orang lain maupun hasil plagiarisme/editan/ubahan/contekan terhadap konten lain yang memiliki hak cipta tanpa ada izin dari pemilik.

                    </li>
                    <li>
                    Konten yang tidak lucu atau tidak dibuat dengan tujuan untuk mengundang tawa/kelucuan. (Sesuai namanya ketiwi.com adalah situs yang berisi konten-konten lucu yang dapat mengundang tawa).

                    </li>
                    <li>
                    Konten yang berisi penyebaran identitas user lain dari ketiwi.com.

                    </li>
                    <li>
                    Konten dengan tujuan promosi/jualan suatu produk atau jasa.

                    </li>
                    <li>
                    
                    Serta konten yang berpotensi melanggar UU ITE.

                    </li>
                </ol> 
                    Admin ketiwi.com berhak untuk menghapus Konten yang berisi salah satu atau lebih poin diatas tanpa pemberitahuan terlebih dahulu. 
            </div>
            );
    }
}

export default (ProhibitedContent);
import React from "react";
import { Slide } from "@material-ui/core";
import useAppBarScroll from "./useAppBarScroll";

export default function HideOnScroll(props) {
  const { children, threshold, scroller, ...other } = props;

  const trigger = useAppBarScroll({ threshold, scroller });

  return (
    <Slide appear={false} direction="down" in={!trigger} {...other}>
      {children}
    </Slide>
  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import { Link } from "react-router-dom";
import { TrendingUpSharp,  Home, ThumbUp, Flag, Folder, Phone, Subscriptions } from '@material-ui/icons';
import LocalizedStrings from 'react-localization';
import { FaTrophy } from 'react-icons/fa';
 
var lang = new LocalizedStrings({
 
 id: {
    liked:"Konten yang Saya sukai",
    marked:"Konten yang Saya tandai",
    uploaded:"Konten yang Saya unggah",
    contact:"Hubungi Kami",
    user_rangk:"Peringkat Pengguna",
    subscriptions:"Langganan Saya",
    change_lang:"Switch to English",
 },en:{
    liked:"Liked Content",
    marked:"Marked Content",
    uploaded:"Uploaded Content",
    contact:"Contact Us",
    user_rangk:"User Rank",
    change_lang:"Ganti ke Bahasa Indonesia",
  }
});


const styles = {
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
};

class SwipeableTemporaryDrawer extends React.Component {
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
  };

  componentDidMount(){

    var language=localStorage.getItem('language');
    if(language==='en')
      lang.setLanguage('en');
    else
      lang.setLanguage('id');
  }

  toggleDrawer = (side, open) => () => {
    this.setState({
      [side]: open,
    });
  };

  switchToBahasa(){
    localStorage.setItem('language','id');
    window.location.reload(); 
  }

  switchToEnglish(){
    localStorage.setItem('language','en');
    window.location.reload(); 
  }


  render() {
    const { classes } = this.props;

    const sideList = (
      <div className={classes.list}>
        <List>
          <Link to="/" style={{textDecoration:"none"}}>
            <ListItem button key="Home">
              <ListItemIcon><Home /></ListItemIcon>
              <ListItemText primary="Home" />
            </ListItem>
          </Link> 
        </List>
        <List>
          <Link to="/trending" style={{textDecoration:"none"}}>
            <ListItem button key="Trending">
              <ListItemIcon><TrendingUpSharp /></ListItemIcon>
              <ListItemText primary="Trending" />
            </ListItem>
          </Link> 
        </List>
        {this.props.isLogin?
        <List>
          <Link to="/subscriptions" style={{textDecoration:"none"}}>
            <ListItem button key="My">
              <ListItemIcon><Subscriptions></Subscriptions></ListItemIcon>
              <ListItemText primary={lang.subscriptions} />
            </ListItem>
          </Link>
        </List>:null
        }
        {/*
        <List>
            <ListItem button key="Subscription">
              <ListItemIcon><Subscriptions /></ListItemIcon>
              <ListItemText primary="Subscription" />
            </ListItem>
        </List>
        */}
        
        <Divider />
         {/*
        <List>
            <ListItem button key="History">
              <ListItemIcon><History /></ListItemIcon>
              <ListItemText primary="History" />
            </ListItem>
        </List>
        */}

        {this.props.isLogin?
        <div><List>
          <Link to="/liked" style={{textDecoration:"none"}}>
            <ListItem button key="Liked">
              <ListItemIcon><ThumbUp /></ListItemIcon>
              <ListItemText primary={lang.liked} />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/marked" style={{textDecoration:"none"}}>
            <ListItem button key="Marker">
              <ListItemIcon><Flag /></ListItemIcon>
              <ListItemText primary={lang.marked} />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/myuploaded" style={{textDecoration:"none"}}>
            <ListItem button key="My">
              <ListItemIcon><Folder /></ListItemIcon>
              <ListItemText primary={lang.uploaded} />
            </ListItem>
          </Link>
        </List>
        </div>:null
        }
        
        <List>
          <Link to="/userrank" style={{textDecoration:"none"}}>
            <ListItem button key="My">
              <ListItemIcon><FaTrophy size={25}/></ListItemIcon>
              <ListItemText primary={lang.user_rangk} />
            </ListItem>
          </Link>
        </List>
        <List>
          <Link to="/contact" style={{textDecoration:"none"}}>
            <ListItem button key="Contact">
              <ListItemIcon><Phone /></ListItemIcon>
              <ListItemText primary={lang.contact} />
            </ListItem>
          </Link>
        </List>
        <List>
        {localStorage.getItem('language')==='en'?
            <ListItem button key="Contact" onClick={this.switchToBahasa} >
              <ListItemIcon>
             
                <img alt="Ganti ke Bahasa Indonesia"  title="Ganti ke Bahasa Indonesia" src={global.memeUrl+'meme_galery/ID.png'} style={{height:25,cursor:"pointer",verticalAlign:"middle",marginTop:15}}></img>
                


              </ListItemIcon>
              <ListItemText primary={lang.change_lang} />
            </ListItem>
            :
            <ListItem button key="Contact" onClick={this.switchToEnglish} >
                <ListItemIcon>
                  <img  alt="Switch to English" title="Switch to English" src={global.memeUrl+'meme_galery/GB.png'} style={{height:25,cursor:"pointer",verticalAlign:"middle",marginTop:15}}></img>
                </ListItemIcon>
                <ListItemText primary={lang.change_lang} />
            </ListItem>
            }
         
        </List>
      
      </div>
    );

   

    return (
      <div>
         <IconButton onClick={this.toggleDrawer('left', true)} className={classes.menuButton} color="inherit" aria-label="Open drawer">
            <MenuIcon />
        </IconButton>
        <SwipeableDrawer
          open={this.state.left}
          onClose={this.toggleDrawer('left', false)}
          onOpen={this.toggleDrawer('left', true)}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={this.toggleDrawer('left', false)}
            onKeyDown={this.toggleDrawer('left', false)}
          >
            {sideList}
          </div>
        </SwipeableDrawer>
        
      </div>
    );
  }
}

SwipeableTemporaryDrawer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SwipeableTemporaryDrawer);
